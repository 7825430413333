<template>
  <div>
    <a-modal width="600px" :title="Title" :visible="visible" :ok-button-props="{ danger: true }" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="cancel">
      <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" ref="formRef" :model="formState" :rules="rules">
        <a-form-item label="字典标签：" name="label">
          <a-input v-model:value="formState.label" style="width: 370px" />
        </a-form-item>
        <a-form-item label="字典值：" name="value">
          <a-input v-model:value="formState.value" style="width: 370px" />
        </a-form-item>
        <a-form-item label="排序：">
          <a-input v-model:value="formState.sort" style="width: 370px" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import {
  DictDetailControllerdictId_post,
  DictDetailControllerdictId_put
} from '@/api/system'
const form = {
  label: '',
  value: '',
  sort: '',
  dictId: ''
}
export default defineComponent({
  name: 'index',
  props: {
    Title: {
      type: String,
      required: false
    },
    FormData: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      pid: '',
      visible: false,
      formState: {
        ...form
      },
      rules: {
        label: [{ required: true, message: '不能为空', trigger: 'change' }],
        value: [{ required: true, message: '不能为空', trigger: 'change' }]
      },
      editor: false
    }
  },
  components: {},
  created() {},
  methods: {
    cancel() {
      this.$refs.formRef.resetFields()
      this.formState = { ...form }
      this.editor = false
      this.visible = false
    },
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true
        if (!this.editor) {
          let obj = {
            label: this.formState.label,
            value: this.formState.value,
            sort: this.formState.sort,
            dictId: this.pid
          }
          DictDetailControllerdictId_post(obj).then((res) => {
            this.visible = false
            this.confirmLoading = false
            this.$message.success('操作成功')
            this.cancel()
            // this.$parent.refresh();
            this.$emit('refresh')
          })
        } else {
          let obj = {
            label: this.formState.label,
            value: this.formState.value,
            sort: this.formState.sort,
            detailId: this.formState.detailId
          }
          DictDetailControllerdictId_put(obj).then((res) => {
            this.visible = false
            this.confirmLoading = false
            this.$message.success('操作成功')
            this.cancel()
            // this.$parent.refresh();
            this.$emit('refresh')
          })
        }
      })
    },
    showModal(val) {
      this.visible = true
      if (val.label) {
        this.editor = true
        this.formState = { ...form, ...val }
      } else {
        this.editor = false
        this.pid = val
      }
    }
  }
})
</script>
<style lang="less">
.page-wrapper {
  padding: 20px 32px;
}

.radio {
  width: 100px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #e0e0e2;
}
</style>
    