import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-wrapper question-type" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_formSearch = _resolveComponent("formSearch")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_pagaTable = _resolveComponent("pagaTable")!
  const _component_popupWindow = _resolveComponent("popupWindow")!
  const _component_specification = _resolveComponent("specification")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_formSearch, {
      ref: "formSearch",
      formConfig: _ctx.formConfig,
      onSearch: _ctx.handleSearch,
      formBtn: _ctx.formBtn
    }, null, 8, ["formConfig", "onSearch", "formBtn"]),
    _createVNode(_component_pagaTable, {
      ref: "table",
      columns: _ctx.purchaseColumns,
      sourceData: _ctx.getDataApi,
      "row-key": "dictId",
      onChange: _ctx.handleChange
    }, {
      headerLeft: _withCtx(() => [
        _createVNode(_component_a_button, {
          type: "default",
          class: "ant-btn-add",
          onClick: _ctx.handleAdd
        }, {
          default: _withCtx(() => [
            _createTextVNode(" 新增 ")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      operation: _withCtx(({ text, record }) => [
        _createVNode(_component_a_button, {
          type: "link",
          onClick: ($event: any) => (_ctx.handleClick(text, record))
        }, {
          default: _withCtx(() => [
            _createTextVNode("编辑")
          ]),
          _: 2
        }, 1032, ["onClick"]),
        _createVNode(_component_a_button, {
          type: "link",
          onClick: ($event: any) => (_ctx.specificationClick(text, record))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" 字典详情")
          ]),
          _: 2
        }, 1032, ["onClick"]),
        _createVNode(_component_a_popconfirm, {
          title: "确定删除吗",
          "ok-text": "是的",
          "cancel-text": "关闭",
          onConfirm: ($event: any) => (_ctx.del(record))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, { type: "link" }, {
              default: _withCtx(() => [
                _createTextVNode("删除")
              ]),
              _: 1
            })
          ]),
          _: 2
        }, 1032, ["onConfirm"])
      ]),
      _: 1
    }, 8, ["columns", "sourceData", "onChange"]),
    _createVNode(_component_popupWindow, {
      ref: "popupWindow",
      Title: _ctx.Title
    }, null, 8, ["Title"]),
    _createVNode(_component_specification, {
      ref: "specificationRef",
      specificationTile: _ctx.specificationTile
    }, null, 8, ["specificationTile"])
  ]))
}