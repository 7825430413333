
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onBeforeMount,
  getCurrentInstance,
  ComponentInternalInstance,
  createVNode,
  watch
} from 'vue'
import pagaTable from '@/components/pagination-table/index'
import formSearch from '@/components/page-search/form'
import popupWindow from './popupNew.vue'
import specification from './specification.vue'
import { DictController_del, DictController_page } from '@/api/system'
import { message } from 'ant-design-vue'
const purchaseColumns = [
  {
    title: 'id ',
    dataIndex: 'dictId'
  },
  {
    title: '名称 ',
    dataIndex: 'name'
  },
  {
    title: '描述 ',
    dataIndex: 'description'
  },
  {
    title: '创建时间',
    dataIndex: 'createdTime'
  },
  {
    title: '更新时间',
    dataIndex: 'updatedTime'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    slots: { customRender: 'operation' }
  }
]
// const replaceFields = {
//   children: "children",
//   title: "menuName",
//   key: "menuId",
// };
export default defineComponent({
  name: 'questionType',
  components: {
    pagaTable,
    popupWindow,
    formSearch,
    specification
  },
  setup(props: any) {
    const { proxy } = getCurrentInstance() as ComponentInternalInstance
    // const treeData = ref<TreeDataItem[]>([]);
    const data = reactive({
      purchaseColumns,
      selectedRowKeys: [],
      extraModel: {},
      formBtn: {
        colProps: { span: 16 },
        formItemProps: {
          wrapperCol: {
            span: 24
          }
        }
      },
      formConfig: [
        {
          colProps: {
            span: 8
          },
          formItemProps: {
            label: '名称:'
          },
          comConfig: {
            tag: 'input', // input, select, date-picker
            key: 'name', // 即是插槽也是字段model key
            props: {
              placeholder: '请输入'
            }
          }
        }
      ]
    })
    const onSelectChange = (selectedRowKeys: never[]) => {
      data.selectedRowKeys = selectedRowKeys
    }
    const table = ref()
    const getDataApi = (params: any) => {
      return new Promise((reslove) => {
        const params_api = {
          currentPage: params.pageIndex,
          pageSize: params.pageSize,
          ...formSearch.value.getQuery()
        }
        DictController_page(params_api).then((res) => {
          reslove(res)
        })
      })
    }
    const handleChange = (
      pagination: any,
      filter: any,
      sorter: any,
      { currentDataSource }: any
    ) => {
      // console.log(pagination, filter, sorter, currentDataSource);
    }
    const handleClick = (text: string, row: any) => {
      Title.value = '编辑'

      popupWindow.value.showModal(row)
    }
    const specificationClick = (text: string, row: any) => {
      // specificationVisible.value = true;
      specificationTile.value = `字典详情`
      specificationRef.value.showModal(row)
    }
    const onClose = () => {
      specificationVisible.value = false
    }
    const formSearch = ref()
    const popupWindow = ref()
    const specificationRef = ref()
    const popupRandom = ref()
    const specificationTile = ref()
    const specificationVisible = ref<boolean>(false)
    const Title = ref()
    const updatedTime = ref([])
    const handleAdd = () => {
      Title.value = '新增'
      popupWindow.value.showModal()
    }
    const handleSearch = (formState: any) => {
      // formSearch.value.getQuery();
      // console.log({ ...formSearch.value.getQuery() });
      // console.log("插槽", { ...updatedTime.value });
      table.value.refresh()
    }
    const refresh = () => {
      table.value.refresh()
      data.selectedRowKeys = []
    }

    const visible = ref<boolean>(false)
    const questions = ref([] as any[])
    const del = (val: any) => {
      DictController_del({ id: val.dictId }).then((res) => {
        table.value.refresh()
        message.success('操作成功')
      })
    }
    const roleId = ref()
    return {
      onClose,
      specificationTile,
      specificationClick,
      specificationVisible,
      questions,
      visible,
      del,
      refresh,
      ...toRefs(data),
      table,
      onSelectChange,
      getDataApi,
      handleChange,
      handleClick,
      formSearch,
      popupWindow,
      specificationRef,
      popupRandom,
      Title,
      handleAdd,
      handleSearch,
      updatedTime
      // success
    }
  }
})
